<template>
  <div class="container">
    <div class="main">
      <div class="m-panel">
        <ul class="panel__main">
          <li>
            <p class="p1">
              <span class="p2">{{ queryNum }}</span
              >次
            </p>
            <p class="p1">查询次数</p>
          </li>
          <li>
            <p class="p1">
              <span class="p2">{{ currentQueryNum }}</span
              >次
            </p>
            <p class="p1">当前已被您扫</p>
          </li>
        </ul>
      </div>
      <div class="validation">
        <div class="validation__main">
          <p class="p1"><span class="p2">身份编号:</span>{{ qrCode }}</p>
        </div>
      </div>
    </div>
    <BaseNav
      :indexs="[
        {
          index: 2,
          tracking: { userVisit: '42', sourceFlag: '4' }
        },
        {
          index: 3,
          tracking: { userVisit: '42', sourceFlag: '5' }
        }
      ]"
    />
    <BaseTipsDialog ref="tipsDialog" />
    <BaseExchangeDialog ref="exchangeDialog" />
    <BaseActivityRulesDialog
      ref="activityRuleDialog"
      @confirm="activityRulesConfirm"
    />
  </div>
</template>

<script>
import BaseTipsDialog from '@/components/base-tips-dialog/base-tips-dialog'
import BaseExchangeDialog from '@/components/base-exchange-dialog/base-exchange-dialog.vue'
import BaseActivityRulesDialog from '@/components/base-activity-rules-dialog/base-activity-rules-dialog.vue'
import preventBack from '@/mixins/prevent-browser-back'
import { mapGetters } from 'vuex'
import { setLocalStorage, getLocalStorage } from '@/utils/storage.js'

import {
  selectCountOfScanQrcodeByQrcode,
  selectCountOfScanQrcodeByQrcodeAndOpenId
} from '@/api/index.js'

export default {
  name: 'Index',
  components: {
    BaseTipsDialog,
    BaseExchangeDialog,
    BaseActivityRulesDialog
  },
  mixins: [preventBack],
  data() {
    return {
      queryNum: '',
      currentQueryNum: ''
    }
  },
  computed: {
    ...mapGetters(['qrCode'])
  },
  methods: {
    //查询次数（活动结束）
    getSelectCountOfScanQrcodeByQrcode() {
      return selectCountOfScanQrcodeByQrcode().then(({ data }) => {
        this.queryNum = data
        if (this.queryNum > 30) {
          this.$refs.tipsDialog.open()
        }
      })
    },
    //当前已被您扫(活动已结束)
    getSelectCountOfScanQrcodeByQrcodeAndOpenId() {
      return selectCountOfScanQrcodeByQrcodeAndOpenId().then(({ data }) => {
        this.currentQueryNum = data
      })
    },
    activityRulesConfirm() {
      setLocalStorage('isActivityRuleConfirm', true)
    },
    onOpenNearby() {
      this.$refs.exchangeDialog.open()
    }
  },
  mounted() {
    if (!getLocalStorage('isActivityRuleConfirm')) {
      this.$refs.activityRuleDialog.open()
    }
    console.log('测试')

    this.$nextRequest(this.getSelectCountOfScanQrcodeByQrcode)
    this.$nextRequest(this.getSelectCountOfScanQrcodeByQrcodeAndOpenId)
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 100px;
  .main {
    padding-top: 650px;
    height: 3117px;
    background: url('../../assets/index.png') no-repeat center top / 100% auto;
  }
  .m-panel {
    .panel__main {
      padding-top: 34px;
      display: flex;
      justify-content: center;
      width: 500px;
      height: 140px;
      margin-left: 180px;
      li {
        position: relative;
        flex: 1 1 auto;
        text-align: center;
        .p1 {
          font-size: 24px;
          color: #333;
        }
        .p2 {
          font-size: 52px;
          color: #e7471f;
        }
        &:after {
          content: ' ';
          position: absolute;
          top: 12px;
          right: 0;
          display: block;
          border-right: 2px dashed #bcac91;
          height: 78px;
        }
      }
      li:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
  .validation {
    padding-top: 35px;
    height: 126px;
    .validation__main {
      float: right;
      margin-right: 40px;
      width: 360px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      color: #333;
      .p1 {
        font-size: 20px;
      }
      .p2 {
        font-size: 24px;
      }
    }
  }
  .u-btn {
    .ico {
      width: 27px;
      height: 33px;
      margin-right: 8px;
    }
    width: 536px;
    color: #e7471f;
  }
}
</style>

<template>
  <van-popup
    v-model="dialogShow"
    class="m-dialog"
    :close-on-click-overlay="false"
  >
    <div class="dialog__main">
      <div class="dialog__header">温馨提示</div>
      <div class="dialog__cont">
        此二维码已被扫超过30次，建议您通过活动热线登记核实{{
          tips
        }}的真伪，感谢您的支持~
      </div>
      <div class="dialog__footer">
        <van-button type="info" @click="onConfirm" class="u-btn"
          >我知道了</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script>
import { isPing, isGuan } from '@/utils/index.js'

export default {
  name: 'BaseAgreementConfirmDialog',
  data() {
    return {
      dialogShow: false
    }
  },
  computed: {
    isPing() {
      return isPing()
    },
    isGuan() {
      return isGuan()
    },
    tips() {
      return this.isPing ? '瓶盖' : '拉环'
    }
  },
  methods: {
    open() {
      this.dialogShow = true
    },
    onCancel() {
      this.dialogShow = false
      this.$emit('cancel')
    },
    onConfirm() {
      this.dialogShow = false
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.m-dialog {
  width: 538px;
  height: 500px;
  background: url('../../assets/tips-dialog.png') no-repeat 0 0 / 100% 100%;
  .dialog__main {
    width: 100%;
    height: 100%;
  }
  .dialog__header {
    padding: 64px 0;
    font-size: 36px;
    color: #d01815;
    font-weight: bold;
    text-align: center;
  }
  .dialog__cont {
    height: 175px;
    padding: 0 60px;
    font-size: 24px;
    color: #161616;
    text-indent: 2em;
  }
  .dialog__footer {
    display: flex;
    justify-content: space-between;
    padding: 40px 32px 51px;
    .van-button {
      width: 250px;
      border-radius: 10px;
      height: 68px;
    }
    .van-button--default {
      color: #d1d1d1;
    }
    .van-button--info {
      background-color: #2d5fe9;
    }
  }
  .link {
    color: #2d5fe9;
    text-decoration: none;
  }
}
</style>

<template>
  <div>
    <h2>2023年红牛消费者促销活动<span> (1元换购)</span></h2>
    <h2>详细规则</h2>
    <br />
    <h3>活动时间：<span>即日起至2023年12月31日止。</span></h3>
    <h3>
      活动范围：<span
        >新疆维吾尔自治区、青海省、四川省甘孜藏族自治州、四川省阿坝藏族羌族自治州、西藏自治区昌都市。</span
      >
    </h3>
    <h3>产品范围：<span>红牛维生素功能饮料（金罐促销装）</span></h3>

    红牛进入中国28周年，为感谢广大消费者的支持，即日起至2023年12月31日止，凡在活动开展地区购买红牛维生素功能饮料促销装产品，拉开拉环显示“1元换购”字样即可凭该完整拉环加1元人民币购买红牛维生素功能饮料1罐。<br />
    “1元换购”数量：18240000个。<br />
    <h3>活动说明：</h3>
    1、“1元换购”换购方式：凭完整“1元换购”拉环至指定换购网点加1元人民币购买红牛维生素功能饮料1罐。<br />
    2、换购截止时间：2023年12月31日，逾期视为自动放弃。<br />
    3、活动中奖率20%，换购商品市场总价值约10032万元。<br />
    4、活动区域：新疆维吾尔自治区、青海省、四川省甘孜藏族自治州、四川省阿坝藏族羌族自治州、西藏自治区昌都市。<br />
    <h3>注意事项：</h3>
    请在参加本活动前仔细阅读本规则中的各项条款，如果您参加了本活动，则红牛公司认为您已理解并同意该规则中的各条款。<br />
    1、红牛公司对拉环的真实性和有效性有鉴定及确认权。<br />
    2、换购拉环需保持清洁、完整，仅有舌片不予兑换。<br />
    3、拉环图样以实物为准，活动站点及相关宣传资料上提供的图片仅供参考。<br />
    4、本促销产品数量9120万罐，如遇不可抗力事件，包括但不限于地震、台风、水灾或其他自然灾害、火灾、爆炸事故、骚乱、战争、政府政策的改变、任何国家主管机关就此次活动做出任何判决、决定、命令，或未能合理控制的任何其他不可预见事件，红牛公司有权在不事先通知的前提下变更或取消本活动。<br />
    5、如果参加者在活动中使用任何不正当的手段参加活动，红牛公司有权在不事先通知的情况下取消其参加活动的资格，同时保留依法追究其法律责任的权利。<br />
    6、红牛公司保留对争议和异议的处理权利。红牛公司对于参加者因参加本活动所发生的任何直接、间接费用和产生的任何损害不负任何责任。<br />
    7、本规则的解释与适用，以及与规则有关的任何争议，均以中华人民共和国法律为依据。<br />
    <h3>活动咨询热线：400-810-1128<br />（工作日8:30-11:30、13:30-17:30）</h3>
    <h3>指定换购网点见官方网站：www.redbull.com.cn</h3>
  </div>
</template>

<script>
export default {
  name: 'BaseActivityRules',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
h2 {
  text-align: center;
  font-size: 30px;
  span {
    font-size: 24px;
  }
}
h3 {
  font-size: 24px;
}
</style>

import request from '@/utils/request'

//查询次数(活动结束)
export function selectCountOfScanQrcodeByQrcode(data) {
  return request({
    url: '/scanQrcodeDetail/selectCountOfScanQrcodeByQrcode',
    method: 'post',
    data
  })
}

//当前已被您扫(活动结束)
export function selectCountOfScanQrcodeByQrcodeAndOpenId(data) {
  return request({
    url: '/scanQrcodeDetail/selectCountOfScanQrcodeByQrcodeAndOpenId',
    method: 'post',
    data
  })
}
